.login-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    .login {
        display: none;
        min-width: 500px;
        background-color: #FFFFFF;
        padding: 30px 50px 30px 50px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        .header {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .email, .password {
            color: #434343;
            font-size: 17px;
            font-weight: bold;
            input {
                border: 2px solid #B6BFF6;
                height: 60px;
                padding: 0 15px;
                border-radius: 5px;
            }
        }
        .lost-wrapper {
            margin-top: 25px;
            margin-bottom: 25px;
            .lost {
                text-decoration: none;
                color: #111E72;
                font-weight: 700;
                font-size: 17px;
            }
            .login-btn {
                background-color: #00A9DA;
                border: 0;
                padding: 15px 25px;
                color: #FFFFFF;
                text-transform: uppercase;
                box-shadow: 15px 15px 0px 0px rgba(0,0,0,0.75);
            }
        }
    }
}