.trains {
  margin: 25px 25px 0 25px;
  h1 {
    margin-bottom: 30px;
    color: #434343;
    font-size: 25px;
    font-weight: 700;
  }
  h2 {
    margin-bottom: 15px;
    color: #434343;
    font-size: 18px;
    font-weight: 700;
  }
  .filter-wrapper {
    .filter {
      .react-datepicker-wrapper {
        width: auto !important;
      }
    }
  }
  table {
    thead {
      tr {
        th {
          text-align: right;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: right;
        }
      }
    }
  }
}