.dashboard {
    .header {
        width: 100%;
        height: 95px;
        background-color: #0F276F;
        .top-navigation {
            height: 100%;
            margin-left: 320px;
            .menu-logo {
                position: absolute;
                top: 8px;
                left: 15px;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    display: inline;
                    margin: 0;
                    padding: 0;
                    a {
                        color: #FFFFFF;
                        font-size: 20px;
                        text-decoration: none;
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    .side-navigation {
        height: 100%;
        width: 300px;
        position: absolute;
        top: 95px;
        left: 0;
        background-color: #00A9DA;
        ul {
            padding: 0;
            list-style: none;
            li {
                margin-bottom: 5px;
                border-bottom: 1px solid #FFFFFF;
                &:hover {
                    cursor: pointer;
                }
                a {
                    padding: 15px;
                    height: 100%;
                    width: 100%;
                    color: #111E72;
                    text-decoration: none;
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }
        }
    }

    #dropdown-user {
        background-color: transparent;
        border: unset;
        color: #FFFFFF;
        font-size: 20px;
    }

    .content {
        margin-left: 300px;
    }

    .menu-logo {
        &:hover {
            cursor: pointer;
        }
    }
}