.freightcar-wrapper {
  margin: 40px;
  table {
    tbody {
      tr {
        td {
          padding: 5px 0;
          border-bottom: 1px solid #BAC1E8;
          font-size: 17px !important;
          &:nth-of-type(1) {
            color: #434343 !important;
          }
          &:nth-of-type(2) {
            padding-left: 150px !important;
            padding-right: 100px !important;
            color: #9F9F9F !important;
          }
        }
      }
    }
  }
}