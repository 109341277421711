.train-wrapper {
  .train-info {
    padding: 40px 40px;
    background-color: #F5F5F5;
    h1 {
      margin-bottom: 30px;
      color: #434343;
      font-size: 25px;
      font-weight: 700;
    }
    table {
      tr {
        td {
          font-size: 20px;
          &:nth-of-type(1) {
            color: #434343;

          }
          &:nth-of-type(2) {
            padding-left: 40px;
            color: #9F9F9F;
          }
        }
      }
    }
  }
  .freightcars-wrapper {
    margin: 40px 40px;
    table {
      thead {
        tr {
          th {
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: right;
          }
        }
      }
    }
  }
}