* {
    font-family: Arial, Helvetica, sans-serif;
}

table {
    thead {
        tr {
            th {
                &:first-child {
                    border-radius: 15px 0 0 0;
                }
                &:last-child {
                    border-right: none;
                    border-radius: 0 15px 0 0;
                }
                padding: 15px !important;
                border-right: 1px solid #BAC1E8;
                border-bottom: 5px solid #0F276F !important;
                background-color: #00A9DA !important;
                color: #FFFFFF !important;
                font-size: 17px !important;
                font-weight: 400 !important;
                text-align: center;
            }

        }

    }
    tbody {
        tr {
            td {
                color: #878686 !important;
            }
        }
    }
}